































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

interface checkData {
  text: string
  value: string
  clearAction: boolean
}

@Component({})
export default class SelectBox extends Vue {
  @Ref() scrollbar!: PerfectScrollbar
  isChecked: string[] | string = []

  get scrollbarVueComponent (): Vue {
    return this.scrollbar as unknown as Vue
  }

  get scrollbarVueComponentRootElement (): Element {
    return this.scrollbarVueComponent.$el
  }

  @Prop({
    type: Array,
    required: true
  })
  private checkData!: Array<checkData>

  @Prop({
    type: String,
    required: false,
    default: 'checkbox'
  })
  private type!: string

  @Prop({
    type: Boolean,
    required: false
  })
  private shadow!: boolean

  private selected: string[] | string = []
  private elementsPosition: Array<number> = []
  private clearVal: Array<checkData> = []
  private elements: Array<HTMLElement> = []

  private mounted (): void {
    // this.setVal(this.selected)
    this.clearVal = this.checkData.filter(e => e.clearAction)
    if (this.shadow) {
      this.elements = Array.from(document.querySelectorAll('.form-group'))
      let visibleElements = 0
      if (window.innerWidth > 767) visibleElements = this.scrollbarVueComponentRootElement.clientHeight / (this.elements[0].offsetHeight * 2)
      else visibleElements = this.scrollbarVueComponentRootElement.clientHeight / (this.elements[0].offsetHeight)
      const countOfVisibleElementInt: string = visibleElements.toFixed(0)
      if (window.innerWidth > 767) this.elements[+countOfVisibleElementInt - 1].style.opacity = '0.5'
      else this.elements[+countOfVisibleElementInt - 2].style.opacity = '0.5'
      this.elementsPosition = [...this.elements].map(e => e.offsetTop)
    }
  }

  private customScroll (ev: { target: HTMLDivElement }): boolean | void {
    if ((ev.target.scrollTop + ev.target.clientHeight + 10) >= ev.target.scrollHeight) {
      document.querySelectorAll<HTMLElement>('.form-group').forEach(e => {
        e.style.opacity = '1'
      })
      return false
    }
    const visibleElements: Array<number> = this.elementsPosition.filter(e => e < ev.target.scrollTop + ev.target.clientHeight)
    const elementVisibleIndex = this.elementsPosition.indexOf(visibleElements[visibleElements.length - 1])
    document.querySelectorAll<HTMLElement>('.form-group').forEach(e => {
      e.style.opacity = '1'
    })
    document.querySelectorAll<HTMLElement>('.form-group')[elementVisibleIndex].style.opacity = '0.5'
  }

  private updateVal (): void {
    if (Array.isArray(this.selected) && this.clearVal.length) this.selected = this.selected.filter(e => e !== this.clearVal[0].value)
    this.$emit('update-val', this.selected)
    this.isChecked = this.selected
  }

  private clearAction (val: string) {
    if (Array.isArray(this.selected)) this.selected = this.selected.filter(e => e === val)
    this.$emit('update-val', this.selected)
  }

  public setVal (val: [] | string | Array<string>): void {
    this.selected = val
  }
}
