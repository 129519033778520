





























import { Component, Vue } from 'vue-property-decorator'
import SelectBox from '@/components/SelectBox.vue'
import { DeviceCompatible } from '@/store/modules/walkthrough/types/pre-test/deviceCompatible'

@Component({
  components: {
    SelectBox
  }
})
export default class Step2 extends Vue {
  $refs!: Vue['$refs'] & { selectBox: SelectBox }
  private selected = ''
  private isModal = false

  private updateVal (ev: string): void {
    this.selected = ev
    this.$store.dispatch('walkthrough/setDeviceCompatible', this.selected === 'Yes' ? DeviceCompatible.YesCompatible : DeviceCompatible.NotCompatible)
  }

  private mounted (): void {
    const previouslySelectedValue = this.$store.getters['walkthrough/deviceCompatible']
    const selectedItem = previouslySelectedValue === 1 ? 'Yes' : previouslySelectedValue === 0 ? 'No' : ''
    if (previouslySelectedValue === '') {
      return
    }
    (this.$refs.selectBox as SelectBox).setVal(selectedItem)
    this.selected = selectedItem
  }

  private goTo (): void {
    this.$router.push('3')
  }
}
