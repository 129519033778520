
























import { Component, Vue } from 'vue-property-decorator'
import { LocaleMessages } from 'vue-i18n'

@Component({
  components: {
  }
})

export default class Step2 extends Vue {
  private name = ''
  private updateVal (): void {
    var prevState = this.$store.getters['walkthrough/userInfo']
    if (this.name !== '') {
      prevState.fullName = this.name
      this.$store.dispatch('walkthrough/setUserInfo', prevState)
      this.goTo()
    }
  }

  private goTo (): void {
    this.$router.push('3')
  }

  private setPlaceholder (): string | LocaleMessages {
    if (window.innerWidth > 767) return this.$t('demographic.step_2.placeholder')
    else return this.$t('demographic.step_2.placeholder_mobile')
  }

  private mounted (): void {
    const userInfo = this.$store.getters['walkthrough/userInfo']
    this.name = userInfo.fullName
  }
}
